import React, { useEffect, useState } from "react";
import BlogCard from "../../components/Blog/BlogCard";
import PaginationDiv from "../../components/Blog/PaginationBlog";
import SideBar from "../../components/Blog/SideBar";
import useLoadData from "../../hooks/useLoadData";
import { useParams } from "react-router-dom";

const BlogsContainer = () => {
  const {endpoint} = useParams()

  const [blogs, setBlogs] = useState([]);

  const data = useLoadData(`https://blog.eternia.mx/api/v.1.0/${endpoint.replace(/_/g, '/')}`);


  useEffect(() => {
    setBlogs(data);
  }, [data, endpoint])
  

  return (
    <section className="blog">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 col-md-8">
            <div className="row">
            {
              blogs.map((blog, index) => (
                <BlogCard
                  key={index}
                  img={`https://blog.eternia.mx/${blog.image}`}
                  title={blog.title}
                  text={blog.text}
                  date={blog.date}
                  dateTime={blog.dateTime}
                  postCount={blog.postCount}
                  url={blog.url}
                />
              ))
            }

            </div>
            <div className="row">
              <div className="col-lg-8 col-md-8"></div>
              <div className="col-lg-4 col-md-4">
                <PaginationDiv />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="mx-4">
              <SideBar articles={data}></SideBar>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogsContainer;
