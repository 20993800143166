import $ from "jquery";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const UpperHeader = () => {
  // Hide header on scroll down
  window.onscroll = function () {
    myFunction();
  };
  function myFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.querySelector("header .container").classList.add(".top-up");
    } else {
      document.querySelector("header .container").classList.remove(".top-up");
    }
  }

  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    // Aside info bar
    $(".aside_open").click(function (e) {
      e.preventDefault();
      $(".aside_info_wrapper").addClass("show");
    });
    $(".aside_close").click(function (e) {
      e.preventDefault();
      $(".aside_info_wrapper").removeClass("show");
    });

    // Sticky Header
    var header = $("header");
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 50) {
        header.addClass("bg-dark sticky");
      } else {
        header.removeClass("bg-dark sticky");
      }
    });
  }, []);

  return (
    <header className="header">
      <div className="container">
        <div className="header_inner d-flex align-items-center justify-content-between">
          <div className="logo">
            <a href="/" className="light_logo">
              <img
                src="images/logo-light.svg"
                alt="Logo de Eternia en color blanco"
              />
            </a>
            <a href="/" className="dark_logo">
              <img
                src="images/logo-dark.svg"
                alt="Logo de Eternia en color negro"
              />
            </a>
          </div>

          <div className="mainnav d-none d-lg-block">
            <ul className="main_menu mRegular">
              <li className="menu-item">
                <a href="/">Inicio</a>
              </li>
              <li className="menu-item">
                <Link to="/Quienes-Somos">Quiénes Somos</Link>
              </li>
              <li className="menu-item">
                <Link to="/Servicios-Eternia">Servicios</Link>
              </li>
              <li className="menu-item">
                <Link to="/Edificio-Eternia">Edificio</Link>
              </li>
              <li className="menu-item">
                <Link to="/Contacto">Contacto</Link>
              </li>
              <li className="menu-item">
                <Link to="/Blog/all-articles_es">Blog</Link>
              </li>
            </ul>
          </div>
          <div className="header_right_part d-flex align-items-center">
            <button className="aside_open">
              <span className="line"></span>
              <span className="line"></span>
              <span className="line"></span>
            </button>
            {/* Mobile Responsive Menu Toggle Button */}
            <button type="button" className="mr_menu_toggle d-lg-none">
              <i className="bi bi-list"></i>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default UpperHeader;
