import { useEffect, useState } from "react";


const useLoadData = ( endpoint) => {

   const [sendData, setSendData] = useState([]);


   useEffect(() => {
      loadData();
   }, [endpoint]);
   
   const loadData = async () => {
      try {
         const response = await fetch(endpoint, { method: 'GET' });
         const data = await response.json();

         setSendData(data);

      } catch (error) {
         console.log(error);
      }
   }

   return sendData;
}

export default useLoadData;