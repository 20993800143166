import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import SectionGridLines from '../../components/SectionGridLines';
import { data } from 'jquery';
import img_fachada_ext from '../../assets/images/eternia/Home/fachada-exterior-eternia.png'
import img_nicho_lobby_1 from '../../assets/images/eternia/Home/nicho-lobby-1.png'
import img_nicho_lobby_2 from '../../assets/images/eternia/Home/nicho-lobby-2.png'
import { Link } from 'react-router-dom';

const TiposNichosHome = () => {
   
    return (
        <div className="projects packery secciones-tipos">
            <SectionGridLines />
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-lg-6 col-sm-6 col-md-6 p-0">
                        <div className='grid-item'>
                            <div className="thumb">
                                <img className="item_image image-big" src={img_fachada_ext} alt="Imagen representativa de la fachada del edificio de Eternia" />
                                <div className="works-info">
                                    <div className="label-text">
                                        <h6 className='mExtraLight fz-16'><Link to="/Servicios-Eternia">NICHOS ETERNIA</Link> </h6>
                                        <h5 className='mBold fz-24'><Link to="/Servicios-Eternia">Nicho para 8 urnas </Link></h5>
                                        <div className="details_link"><Link to="/Servicios-Eternia"><span className="link_text">VER MÁS</span> <span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-6 col-md-6 p-0">
                        <div className='grid-item'>
                            <div className="thumb">
                                <img className="item_image image-sm" src={img_nicho_lobby_1} alt="Imagen representativa de la fachada principal del edificio de Eternia" />
                                <div className="works-info">
                                    <div className="label-text">
                                        <h6 className='mExtraLight fz-16'><Link to="/Servicios-Eternia">NICHOS ETERNIA</Link> </h6>
                                        <h5 className='mBold fz-24'><Link to="/Servicios-Eternia">Nicho para 8 urnas</Link> </h5>
                                        <div className="details_link"><Link to="/Servicios-Eternia"><span className="link_text">VER MÁS</span> <span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='grid-item'>
                            <div className="thumb">
                                <img className="item_image image-sm" src={img_nicho_lobby_2} alt="Imagen representativa de la capilla del edificio de Eternia" />
                                <div className="works-info">
                                    <div className="label-text">
                                        <h6 className='mExtraLight fz-16'><Link to="/Servicios-Eternia">NICHOS ETERNIA</Link> </h6>
                                        <h5 className='mBold fz-25'><Link to="/Servicios-Eternia">Nicho para 8 urnas</Link></h5>
                                        <div className="details_link"><Link to="/Servicios-Eternia"><span className="link_text">VER MÁS</span> <span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TiposNichosHome;