import React from 'react';
import img_recepcion from '../../assets/images/eternia/Home/recepcion-eternia.png';
import nicho_eternia_sobrepuesta from '../../assets/images/eternia/Home/nicho-eternia-1.png';
import { Link } from 'react-router-dom';

const Recordemos = () => {

    return (
        <div className="about style_two recordemos">
            <div className="container-fluid p-0">
                <div className="row align-items-center">
                    <div className="col-lg-1 col-md-1 col-sm-1"></div>
                    <div className="col-lg-5 col-md-5 col-sm-5 aos-init aos-animate" data-aos="fade-down" data-aos-duration="300">
                        <div className="about_image">
                            <img src={img_recepcion} alt="Imagen representativa de la recepción del primer piso del edificio de eternia" className="abt_img_1" />
                            <img src={nicho_eternia_sobrepuesta} alt="Imagen ilustrativa de un tipo de nicho en el edificio de eternia" className="abt_img_2" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-5 col-sm-5 p-0 aos-init aos-animate" data-aos="fade-down" data-aos-duration="300">
                        <div className="about_text_inner">
                            <div className="recordemos-isotipo">
                                <div className="about_text mt-5 txt-recordemos-isotipo">
                                    <div className="icon_box mb-5">
                                        <img src="images/logo-light.svg" alt="Representación del isotipo de Eternia en color negro" className="dark" />
                                        <img src="images/logo-dark.svg" alt="Representación del isotipo de Eternia en color blanco" className="light " />
                                    </div>
                                    <h1 className="text-white mExtraLight fz-32 text-uppercase">Recordemos a <br /> nuestros seres queridos</h1>
                                    <h2 className="text-white mBold fz-50">de una manera excepcional</h2>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10">
                                    <div className="about_text mt-5">
                                        <p className='mRegular fz-20 text-white'>
                                            Somos una empresa líder que entendemos que cada detalle
                                            cuenta cuando se trata de honrar y recordar a tus seres queridos
                                            de una manera excepcional. Nos enorgullece presentarte una
                                            experiencia única y personalizada que combina la elegancia, el 
                                            cuidado y la atención al detalle. 
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="details_link">
                                <Link to="/Quienes-Somos">
                                    <span className="link_text">CONOCE SOBRE NOSTOROS</span>
                                    <span className="link_icon">
                                        <span className="line"></span>
                                        <span className="circle"></span>
                                        <span className="dot"></span>
                                    </span>
                                </Link>
                            </div>

                           
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Recordemos;