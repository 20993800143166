import React from "react";
import { Link } from "react-router-dom";

const PaginationDiv = () => {
  return (
    <div className="pagination-div">
      <ul className="pagination">
        <li>
          <Link to="#">
            <i className="ion-chevron-left"></i>
          </Link>
        </li>
        <li>
          <Link className="page-number current" to="#">
            01
          </Link>
        </li>
        <li>
          <Link className="page-number" to="#">
            02
          </Link>
        </li>
        <li>...</li>
        <li>
          <Link className="page-number" to="#">
            10
          </Link>
        </li>
        <li>
          <Link to="#">
            <i className="bi bi-chevron-right"></i>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default PaginationDiv;
