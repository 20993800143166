import React from "react";

import useLoadData from "../../hooks/useLoadData";
import SideBar from "../../components/Blog/SideBar";

const BlogDetailsContainer = ({details}) => {

  const data = useLoadData('https://blog.eternia.mx/api/v.1.0/all-articles/es');

  const componentsMapping = {
    'main_title': (content) =>(
        <div className='row'>
            <div className='col-12 col-12 col-md-7 col-lg-7'>
                <h1>{ content }</h1>
               
            </div>
        </div>
    ),
    'image': (content) => (
        <div className="row">
            <img className='img-fluid' src={ `https://blog.eternia.mx/${content }`} alt={ content } />
        </div>
    ),
    'subtitle': (content) => (
        <div className='row'>
            <h2>{ content }</h2>
        </div>
    ),
    'paragraph': (content) => (
        <div className='row'>
            <p>{ content }</p>
        </div>
    )
  };

  return (
    <main className="wrapper">
      <section className="project-details bg-dark-200">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <div className="project-details_inner">

                <div className="post_content">
                {
                    details.map((article, index) => {
                        const { type, content } = article;
                        const renderComponent = componentsMapping[type];

                        if( renderComponent ) {
                            return renderComponent(content);
                        }

                        return null;
                    })
                }
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4">
              <SideBar articles={data}></SideBar>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default BlogDetailsContainer;
