import React from "react";
import video_loop from '../../assets/videos/eternia-loop-02.mp4';
import { Link } from 'react-router-dom';

const VideoServiciosEternia = () => {
    return (
        <div className="bg-Video-Servicios" id="servicios_title">
            <video autoPlay="autoPlay" muted="muted" loop="loop" playsInline>
                <source src={video_loop} type="video/mp4" />
            </video>
            <div className="txt-servicios-video">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                            <div className="tit-servicios">
                                <h1 className="bRegular text-uppercase fz-100">
                                    En cada nicho, honramos vidas y memorias.
                                </h1>
                                <p className="mRegular text-uppercase fz-22">
                                    Nuestros nichos son cuidadosamente diseñados para ofrecer un espacio de tranquilidad y belleza.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5"></div>
                        <div className="col-lg-3">
                            <div className="details_link">
                                <Link to="/Contacto">
                                    <span className="link_text">Conoce más</span>
                                    <span className="link_icon">
                                        <span className="line"></span>
                                        <span className="circle"></span>
                                        <span className="dot"></span>
                                    </span>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoServiciosEternia;