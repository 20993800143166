import React from 'react';
import { Link } from 'react-router-dom';

const TiposNichosCard = ({ data }) => {
    const { img, name, designation } = data;
    return (
        <div className="col-lg-4 col-md-4">
            <div className="team-block">
                <img src={img} alt="img" />
                <h5 className="text-white mBold fz-30">{name}</h5>
                <h6 className="text-white mRegular fz-22">{designation}</h6>
                <div className="details_link">
                    <Link to="/Contacto" preventScrollReset={true}>
                        <span className="link_text">MÁS INFORMACIÓN</span>
                        <span className="link_icon">
                        <span className="line"></span>
                        <span className="circle"></span>
                        <span className="dot"></span>
                        </span>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default TiposNichosCard;