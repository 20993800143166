import $ from 'jquery';
import React, { useEffect } from 'react';
import SectionGridLines from '../../../components/SectionGridLines';
import { Link } from 'react-router-dom';

const Footer = () => {
    //  

    useEffect(() => {
        // Totop Button
        $('.totop a').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, '300');
        });

        // Hide header on scroll down
        const nav = document.querySelector(".header");
        const scrollUp = "top-up";
        let lastScroll = 800;

        window.addEventListener("scroll", () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 800) {
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
                return;
            }

            if (currentScroll > lastScroll) {
                // down
                nav.classList.add(scrollUp);
                $('.totop').addClass('show');

            } else if (currentScroll < lastScroll) {
                // up
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
            }
            lastScroll = currentScroll;
        });

    }, []);
    return (
        <>
            <footer className="footer bg-dark-200 box_padding">
                <div className="footer_inner bg-black">
                    <div className="container">
                        <div className="row  footer-eternia">
                            <div className="col-lg-1 col-md-2 col-sm-2">
                                <div className="section-header">
                                    <h2>Contacto</h2>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-5">
                                <div className="communication">
                                    <div className="info_body">
                                        <h6 className='mRegular txt-oro'>MENÚ</h6>
                                        <h5 className='mRegular text-white'><a href="/" className='text-white'>Inicio</a></h5>
                                        <h5 className='mRegular'><Link to="/Quienes-Somos" className='text-white'>Quiénes Somos</Link></h5>
                                        <h5 className='mRegular'><Link to="/Servicios-Eternia" className='text-white'>Servicios</Link></h5>
                                        <h5 className='mRegular'><Link to="/Edificio-Eternia" className='text-white'>Edificio Eternia</Link></h5>
                                        <h5 className='mRegular'><Link to="/Contacto" className='text-white'>Contacto</Link></h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-5">
                                <div className="communication">
                                    <div className="info_body">
                                        <h6 className='mRegular fz-18 txt-oro'>EDIFICIO ETERNIA</h6>
                                        <h5 className='mRegular fz-18'>
                                            <a href="https://maps.app.goo.gl/Ep9DqmZvRoWQTwXA9" target="_blank" rel="noopener noreferrer" className='text-white'>
                                                <p className='p-0 m-0'>Miguel Hidalgo Pte. #1003,</p>
                                                <p className='p-0 m-0'>Av C.P: 50080,</p>
                                                <p className='p-0 m-0'>Barrio de San Bernandino</p>
                                                <p className='p-0 m-0'>Toluca de Lerdo, México</p>          
                                            </a>
                                        </h5>
                                    </div>
                                    <div className="info_body">
                                        <h6 className='mRegular txt-oro fz-18'>EMAIL</h6>
                                        <h5 className='mRegular fz-18'>
                                            <a href="mailto:contacto@eternia.mx" target="_blank" rel="noopener noreferrer" className='text-white'>contacto@eternia.mx</a> 
                                        </h5>
                                    </div>
                                    <div className="info_body">
                                        <h6 className='mRegular fz-18 txt-oro'>TELÉFONO</h6>
                                        <h5 className='mRegular fz-18'>
                                            <a href="tel:8004722767" target="_blank" rel="noopener noreferrer" className='text-white'>800 472 2767</a>
                                        </h5>
                                        <h5 className='mRegular fz-18'>
                                            <a href="https://api.whatsapp.com/send?phone=5586398141" target="_blank" rel="noopener noreferrer" className='text-white'>55 8639 8141</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 col-md-5 col-sm-5">
                                <img src="images/eternia/Icons-Eternia/isotipo-gde.png" alt="Isotipo grande de Eternia"  className='isotipo-footer'/>
                                <div className="footer_elements d-flex align-items-center justify-content-end">
                                    <div className="footer_elements_inner">
                                        <div className="footer_logo">
                                            <a href="/" className="light_logo"><img src="images/logo-light.svg" alt="logo" /></a>
                                        </div>
                                        <div className="footer_social">
                                            <ul className="social_list">
                                                <li className="facebook"><a href='https://www.facebook.com/eternia1.mx/' target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a></li>
                                                <li className="instagram"><a href='https://www.instagram.com/eterniamx/' target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a></li>
                                                <li className="tiktok"><a href='https://www.tiktok.com/@eterniamx' target="_blank" rel="noopener noreferrer"><i className="bi bi-tiktok"></i></a></li>
                                                {/* <li className="youbetube"><a><i className="bi bi-youtube"></i></a></li> */}
                                            </ul>
                                        </div>
                                        <div className="terms_condition">
                                            <ul className='mRegular fz-18'>
                                                <li><Link to='/Terminos-Condiciones-Eternia'>Términos</Link></li>
                                                <li><Link to='/Aviso-Privacidad-Eternia'>Aviso</Link></li>
                                                <li><Link to='/Politicas-Cookies-Eternia'>Cookies</Link></li>
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <p>“Las imágenes son exclusivamente de carácter ilustrativo. Las especificaciones y demás particularidades estarán sujetas a modificaciones”.</p>
                            </div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-4 col-md-2 col-sm-2"></div>
                            <div className="col-lg-4 col-md-5 col-sm-5">
                            <div className="copyright">
                                <p className='mRegular fz-18'>©2023 ETERNIA. Todos los derechos reservados</p>
                            </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-5">
                                <div className="text-end">
                                    <a href="http://www.blueberry.mx" target="_blank" rel="noopener noreferrer">
                                        <img src="https://res.cloudinary.com/dej55trpk/image/upload/v1675785642/we-materialize_xwuusu.gif" alt="Materialized by Blueberry" className='bb-logo'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <SectionGridLines />
                </div>
            </footer >

            <div className="totop">
                <a href="#">UP</a>
            </div>
        </>
    );
};

export default Footer;