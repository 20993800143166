import React from "react";
import loop from '../../assets/videos/eternia-loop-01.mp4'
import { Link } from 'react-router-dom';

const VideoHome = () =>{
    return(
        <div className="VideoHomeEternia" id="somos_title">
            <video autoPlay="autoPlay" muted="muted" loop="loop" playsInline>
                <source src={loop} type="video/mp4"/>
            </video>
            <div className="txt-Home-video">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-7">
                            <div className="tit-Home aos-init aos-animate" data-aos="fade-up">
                                <h1 className="bRegular fz-100">RECUERDOS QUE TRASCIENDEN EN EL TIEMPO</h1>
                                <p className="mRegular text-uppercase fz-22">
                                    Nuestros espacios ofrecen elegancia, cuidado y la atención al detalle.
                                </p>
                                <div className="details_link">
                                    <Link to="/Contacto">
                                        <span className="link_text">Conoce más</span>
                                        <span className="link_icon">
                                            <span className="line"></span>
                                            <span className="circle"></span>
                                            <span className="dot"></span>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default VideoHome;