import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import useLoadData from "../../hooks/useLoadData";
import { useState } from "react";



const SideBar = ({articles}) => {
  const categories = useLoadData('https://blog.eternia.mx/api/v.1.0/all-categories/es');

  const [search, setSearch] = useState('');
  const [articlesLoaded, setArticlesLoaded] = useState([]);

  const handleSearch = async(value) => {
    if (value.length >  0) {
      setSearch(value);
      
      const resp = await fetch(`https://blog.eternia.mx/api/v.1.0/search/articles/${value}`,  { method: 'GET' });
      const data = await resp.json();
  
      setArticlesLoaded(data)
    }
    else {
      setSearch(value);
      setArticlesLoaded(articles);
    }
  }

  useEffect(() => {
    setArticlesLoaded(articles)
  }, [articles])
  


  return (
    <div className="sidebar">
      <div className="widget widget_block widget_search">
        <form method="get" className="wp-block-search">
          <div className="wp-block-search__inside-wrapper ">
            <input
            value={search}
              type="search"
              className="wp-block-search__input"
              name="search"
              placeholder="Buscar"
              required=""
              onChange={ e => {
                handleSearch(e.target.value)
              }}
            />
            <button type="submit" className="wp-block-search__button">
              <i className="bi bi-search"></i>
            </button>
          </div>
        </form>
      </div>
      {/* <!-- end widget --> */}

      <div className="widget widget_block">
        <div className="wp-block-group__inner-container">
          <h2 className="widget-title">
            Publicaciones recientes <span className="title-line"></span>
          </h2>
          <ul className="wp-block-latest-posts__list wp-block-latest-posts">
            {
              articlesLoaded.slice(-6).map((article, index) => (
                <li key={index}>
                  <Link to={`/Articulo-Blog/${article.url}`}>
                    {article.title}
                  </Link>
                </li>
              ))
            }
          </ul>

        </div>
      </div>
      {/* <!-- end widget --> */}

      <div className="widget widget_block">
        <div className="wp-block-group__inner-container">
          <h2 className="widget-title">
            Categorías <span className="title-line"></span>
          </h2>
          <ul className="wp-block-latest-posts__list wp-block-latest-posts">
            {
              categories.map((categorie, index) => (
                <li key={categorie.category}>
                  <Link to={`/Blog/search_article_category_${categorie.id}`}>
                    {categorie.category}
                  </Link>
                </li>
              ))
            }
          </ul>
        </div>
      </div>
      {/* <!-- end widget --> */}
    </div>
  );
};

export default SideBar;
