import React from 'react';

const PageBanner = (props) => {
    return (
        <>
            <div className="page_header" id='contacto-inicio'>
                <div className="page_header_inner">
                    <div className="container">
                        <div className="page_header_content d-flex align-items-center justify-content-between">
                            <h2 className="heading bRegular fz-100">{props.title}</h2>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageBanner;