import React from "react";

const PoliticaCookies = () => {
    return (
        <div className='pt-5'>
            <section className="contact_us bg-dark-200">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-12">
                            <div className="section-header aos-init aos-animate" data-aos="fade-up" data-aos-duration="3000">
                                <h1 className="text-white text-uppercase mb-4 mBold fz-38">POLÍTICA DE COOKIES</h1>
                                <p className='mRegular fz-24 text-white'>
                                    Términos y condiciones del servicio ETERNIA.
                                </p>
                                <p>
                                    En ETERNIA. creemos que hay que ser claros sobre el modo en que recabamos y
                                    utilizamos tus datos. Para ofrecer un mayor nivel de transparencia, esta Política
                                    proporciona información detallada sobre cómo y cuándo utilizamos cookies.
                                    Esta Política de cookies se aplica a cualquier producto o servicio de ETERNIA
                                    asociado a esta política o que la incorpore por referencia.
                                </p>
                                <p className='mRegular fz-24 text-white'>
                                    ¿Utiliza ETERNIA cookies?
                                </p>
                                <p>
                                    Sí. Utilizamos cookies y otras tecnologías para garantizar que todos los usuarios de
                                    ETERNIA tengan la mejor experiencia posible. Las cookies nos ayudan a mantener
                                    tu cuenta segura. Si sigues utilizando nuestros servicios, estarás dando tu
                                    consentimiento para el uso de las cookies y de otras tecnologías similares para los
                                    fines que se describen en esta Política
                                </p>
                                <p className='mRegular fz-24 text-white'>
                                    ¿Qué es una cookie?
                                </p>
                                <p>
                                    Una cookie es un pequeño archivo colocado en tu dispositivo electrónico que
                                    habilita las funcionalidades de ETERNIA por ejemplo, las cookies nos permiten
                                    identificar tu dispositivo, ofrecerte acceso seguro ETERNIA y a nuestros sitios en
                                    general, e incluso nos ayudan a saber si alguien intenta acceder a tu cuenta desde
                                    otro dispositivo.
                                    Las cookies también te permiten compartir contenidos con facilidad en ETERNIA y
                                    nos ayudan a mostrarte anuncios relevantes para ti.
                                </p>
                                <p className='mRegular fz-24 text-white'>
                                    ¿Cuándo utiliza ETERNIA cookies?
                                </p>
                                <p>
                                    Utilizamos cookies en nuestros sitios web (“https://eternia.mx//“) y en las
                                    aplicaciones móviles. Cualquier navegador que visite estos sitios web recibirá
                                    cookies nuestras. También colocamos cookies en tu navegador cuando visitas sitios
                                    que no son de ETERNIA pero que albergan nuestros complementos (por ejemplo, el
                                    botón «Compartir» ETERNIA o etiquetas.)
                                </p>
                                <p className='mRegular fz-24 text-white'>
                                    ¿Qué tipo de cookies utiliza ETERNIA?
                                </p>
                                <p>
                                    Utilizamos dos tipos de cookies: persistentes y de sesión.
                                    Una cookie persistente nos ayuda a reconocerte como usuario existente para que
                                    te resulte más sencillo volver a ETERNIA o interactuar con nuestros servicios sin
                                    necesidad de iniciar sesión de nuevo. Una vez que hayas iniciado sesión, la cookie
                                    persistente permanecerá en tu navegador y ETERNIA la leerá cuando regreses a
                                    alguno de nuestros sitios web o a sitios de socios que utilizan nuestros servicios
                                    (como, por ejemplo, los botones de compartir o de solicitar de empleo).
                                    Las cookies de sesión solo duran lo que se mantenga la sesión (normalmente, lo
                                    que dure la visita que estés realizando en ese momento a un sitio web o la sesión
                                    con el navegador).
                                </p>
                                <p className='mRegular fz-24 text-white'>
                                    ¿Para qué se usan las cookies?
                                </p>
                                <p>
                                    ETERNIA; recordar tus preferencias y ofrecerle una experiencia personalizada
                                    basada en tu configuración. Las cookies también permiten que tus interacciones en
                                    ETERNIA sean más rápidas y seguras. Además, las cookies no ETERNIA; y te
                                    ofrecen funcionalidades personalizadas a través de los complementos de ETERNIA
                                    como nuestro botón «Compartir».
                                    Categorías de uso Descripción
                                    Autenticación: Si has iniciado sesión en ETERNIA las cookies nos ayudan a
                                    mostrarte la información adecuada y a personalizar tu experiencia. Seguridad
                                    Utilizamos cookies para activar y respaldar nuestras funcionalidades de seguridad, y
                                    para ayudarnos a detectar actividades fraudulentas e infracciones de nuestras
                                    Condiciones de uso. Preferencias, funcionalidades y servicios. Las cookies pueden
                                    decirnos qué idiomas prefieres y cuáles son tus preferencias de comunicación.
                                    Pueden ayudarte a rellenar formularios en ETERNIA con mayor facilidad. También te
                                    proporcionan funcionalidades, información y contenidos personalizados
                                    conjuntamente con nuestros complementos. Puedes obtener más información sobre
                                    los complementos en nuestra Política de privacidad.
                                    Publicidad: Podemos usar las cookies para mostrarte publicidad relevante tanto
                                    dentro como fuera de los sitios web de ETERNIA. También podemos usar una
                                    cookie para saber si alguien vio un anuncio, lo visitó en otro momento y realizó una
                                    acción (como descargar un documento o hacer una compra) en el sitio web del
                                    anunciante. Asimismo, nuestros socios pueden usar una cookie para determinar si
                                    hemos mostrado un anuncio y qué resultados obtuvo, o para obtener información
                                    sobre cómo interaccionamos con ellos. También podemos colaborar con un socio
                                    para mostrarte un anuncio dentro o fuera del sitio web de ETERNIA, como después
                                    de haber visitado el sitio web de un socio o aplicación.
                                    Rendimiento: Análisis e investigación Las cookies nos ayudan a averiguar el
                                    rendimiento de nuestros sitios web y de los complementos en lugares diferentes.
                                    También usamos cookies para comprender, mejorar e investigar productos,
                                    funcionalidades y servicios, incluso cuando accedes a ETERNIA desde otros sitios
                                    web, aplicaciones o dispositivos como tu ordenador del trabajo o tu teléfono móvil.
                                </p>
                                <p className='mRegular fz-24 text-white'>
                                    ¿Qué es la tecnología DNT?
                                </p>
                                <p>
                                    «Do Not Track» (DNT) es un concepto promovido por autoridades reguladoras
                                    estadounidenses, como la Comisión Federal de Comercio de EE. UU., para el sector
                                    de Internet con el objetivo de desarrollar e implementar un mecanismo que permita
                                    a los internautas controlar el seguimiento o rastreo que se realiza de sus actividades
                                    en línea en los distintos sitios web a través de la configuración de sus navegadores.
                                    El World Wide Web Consortium (W3C) lleva tiempo trabajando con agentes del
                                    sector, navegadores de Internet, empresas tecnológicas, entidades normativas para
                                    desarrollar un estándar de tecnología DNT. Aunque se han realizado algunos
                                    avances, han sido escasos. Hasta la fecha no se ha adoptado ninguna norma al
                                    respecto. Como tal ETERNIA no responde a señales de «no rastreo».
                                </p>
                                <p className='mRegular fz-24 text-white'>
                                    ¿Cómo se usan las cookies para fines publicitarios?
                                </p>
                                <p>
                                    Las cookies y otras tecnologías publicitarias como las balizas web, los píxeles y las
                                    etiquetas nos ayudan a presentarte anuncios relevantes de manera más efectiva.
                                    También nos ayudan a ofrecerte datos agregados de auditoría, estudios e informes
                                    para anunciantes, a entender y mejorar nuestro servicio y a saber qué contenidos se
                                    te han mostrado. Nota: Debido a que tu navegador puede solicitar estos anuncios y
                                    balizas directamente a los servidores de redes publicitarias, estas redes pueden ver,
                                    editar o establecer sus propias cookies como si hubieras solicitado ver una página
                                    web de su sitio. Los anuncios en ETERNIA también pueden incluir cookies de
                                    terceros.
                                    Si has iniciado sesión en ETERNIA o en otro Servicio al que se hace referencia en
                                    esta Política de cookies, o si visitas el sitio web de un tercero con la condición de
                                    socio de ETERNIA y te identifica una de nuestras cookies en tu dispositivo, el uso
                                    (como tu conducta de navegación) y los datos de los registros (como tu dirección de
                                    IP) se asociará a tu cuenta, tal y como se describe en la Sección 1.3 de nuestra
                                    Política de privacidad. También usamos los datos agregados de terceros, los datos
                                    de tu perfil y tu actividad ETERNIA.
                                    Si eres miembro ETERNIA pero no has iniciado sesión en tu cuenta en un
                                    navegador, ETERNIA puede continuar registrando tus interacciones con nuestros
                                    servicios en ese navegador durante 30 días para generar análisis de uso asociados
                                    a nuestros servicios, que podremos compartir de manera agregada con nuestros
                                    clientes de publicidad. A menos que borres esas cookies de tu navegador, es posible
                                    que utilicemos esa información para: Mostrar publicidad más relevante y basada en
                                    tus intereses.
                                    Facilitar informes agregados de actividad publicitaria a los anunciantes y a los sitios
                                    web que alojan esos anuncios. Ayudar a los titulares de las aplicaciones y los sitios
                                    web a conocer mejor la forma en que los visitantes interactúan con sus sitios o
                                    aplicaciones. Detectar posibles fraudes y otros riesgos y proteger de ellos a
                                    nuestros usuarios y socios. Mejorar nuestros productos.
                                    Para obtener más información sobre el uso que realizamos de las cookies y
                                    tecnologías similares con fines publicitarios, así como sobre los controles
                                    disponibles para Miembros y Visitantes, consulta nuestra Política de privacidad.
                                </p>
                                <p className='mRegular fz-24 text-white'>
                                    ¿Qué cookies de terceros utiliza ETERNIA?
                                </p>
                                <p>
                                    En nuestra tabla de cookies encontrarás información sobre las cookies de terceros
                                    que alojamos en nuestros sitios. Ten en cuenta que los nombres de las cookies, los
                                    píxeles y otras tecnologías pueden cambiar con el tiempo. Ten en cuenta también
                                    que las empresas y otro tipo de organizaciones que patrocinan ETERNIA para
                                    analizar tu interés en ellos.
                                    Control sobre las cookies
                                    La mayoría de los navegadores te permite controlar las cookies mediante las
                                    preferencias de configuración. Ahora bien, si limitas la capacidad de los sitios web
                                    para guardar cookies, es posible que se resienta tu experiencia general de uso, ya
                                    que no será una experiencia personalizada. También puede que no te sea posible
                                    guardar valores de configuración personalizados como la información de inicio de
                                    sesión.
                                </p>
                                <p className='mRegular fz-24 text-white'>
                                    ¿Qué hago si no quiero que se guarden cookies en mi equipo o si quiero eliminar las
                                    que ya hay guardadas?
                                </p>
                                <p>
                                    Si eres Visitante, puedes autoexcluirte de nuestras cookies de publicidad aquí. En el
                                    caso de los Miembros, la configuración de anuncios se encuentra aquí. Si no
                                    quieres recibir cookies, también puedes cambiar la configuración de tu navegador
                                    en tu ordenador u otro dispositivo electrónico que estés usando para acceder a
                                    nuestros servicios. Si usas ETERNIA sin cambiar la configuración de tu navegador,
                                    deducimos que deseas recibir cookies del sitio web de ETERNIA la mayoría de los
                                    navegadores también te ofrecen la opción de revisar y de eliminar las cookies,
                                    incluidas las de ETERNIA en cuenta que el sitio web de ETERNIA funcionará
                                    correctamente sin las cookies.
                                    Ten en cuenta que el sitio web de ETERNIA funcionará correctamente sin las
                                    cookies.
                                    Para averiguar más sobre las cookies, incluidas las cookies que han sido
                                    establecidas y cómo gestionarlas y borrarlas, visita www.allaboutcookies.org, o
                                    www.aboutcookies.org.
                                    Otros recursos útiles
                                    Si deseas más información sobre el uso que hacen de las cookies los anunciantes,
                                    puede que te resulten útiles los siguientes enlaces:
                                </p>
                                <ul>
                                    <li>
                                        Alianza Europea de Publicidad Digital Interactiva (UE)
                                    </li>
                                    <li>
                                        Internet Advertising Bureau (EE. UU.)
                                    </li>
                                    <li>
                                        Internet Advertising Bureau (UE)
                                    </li>
                                </ul>
                                <p>
                                    Las empresas que desarrollan los navegadores proporcionan páginas de ayuda
                                    para la gestión de cookies en sus productos. Si lo deseas, puedes encontrar
                                    información adicional más abajo.
                                </p>
                                <ul>
                                    <li>
                                        Google Chrome
                                    </li>
                                    <li>
                                        Internet Explorer Mozilla Firefox
                                    </li>
                                    <li>
                                        Safari (versión escritorio)
                                    </li>
                                    <li>
                                        Safari (versión móvil)
                                    </li>
                                    <li>
                                        Navegador Android
                                    </li>
                                    <li>
                                        Opera
                                    </li>
                                    <li>
                                        Opera (versión móvil)
                                    </li>
                                </ul>
                                <p>
                                    Si necesitas información sobre otro navegador, consulta la documentación que te
                                    facilite su fabricante.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default PoliticaCookies;