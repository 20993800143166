import React from 'react';
import Form from './Form';
import Paragraph from './Paragraph';
import SectionGridLines from './SectionGridLines';

const ContactForm = () => {
    return (
        <section className="contact_us bg-dark-200">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-5">
                        <div className="section-header aos-init aos-animate" data-aos="fade-up" data-aos-duration="3000">
                            <h1 className="text-white text-uppercase mb-4 mBold fz-38">¿Tienes alguna duda?</h1>
                            <h4 className="text-white text-uppercase mb-4 mBold fz-38">¡Contáctanos!</h4>
                            <Paragraph text='Estamos aquí para responder a todas sus preguntas y ayudarlo en cada paso del proceso. No dude en comunicarse con nosotros de la manera que le resulte más conveniente.' />
                            <div className="info_body mt-4">
                                <h6 className='mRegular txt-oro fz-18'>EDIFICIO ETERNIA</h6>
                                <h5 className='mRegular fz-18'>
                                    <a href="https://maps.app.goo.gl/Ep9DqmZvRoWQTwXA9" target="_blank" rel="noopener noreferrer" className='text-white'>
                                        Av. Miguel Hidalgo Pte. #1003, C.P: 50080, Barrio de San Bernandino Toluca de Lerdo, México
                                    </a>
                                </h5>
                            </div>
                            <div className="info_body mt-4">
                                <h6 className='mRegular txt-oro fz-18'>EMAIL</h6>
                                <h5 className='mRegular fz-18'>
                                    <a href="mailto:contacto@eternia.mx" target="_blank" rel="noopener noreferrer">contacto@eternia.mx</a>
                                </h5>
                            </div>
                            <div className="info_body mt-4">
                                <h6 className='mRegular txt-oro fz-18'>TELÉFONO</h6>
                                <h5 className='mRegular fz-18'>
                                    <a href="tel:8004722767" target="_blank" rel="noopener noreferrer">800 472 2767</a>
                                </h5>
                                <h5 className='mRegular fz-18 pt-3'>
                                            <a href="https://api.whatsapp.com/send?phone=5586398141" target="_blank" rel="noopener noreferrer" className='text-white'>55 8639 8141</a>
                                        </h5>
                            </div>
                            <div className="footer_elements d-flex align-items-center justify-content-start mt-5">
                                <a href='https://www.facebook.com/eternia1.mx/' target="_blank" rel="noopener noreferrer" className='me-3'><i className="bi bi-facebook"></i></a>
                                <a href='https://www.instagram.com/eterniamx/' target="_blank" rel="noopener noreferrer" className='me-3'><i className="bi bi-instagram"></i></a>
                                <a href='https://www.tiktok.com/@eterniamx' target="_blank" rel="noopener noreferrer"><i className="bi bi-tiktok"></i></a>
                                            {/* <li className="youbetube"><a><i className="bi bi-youtube"></i></a></li> */}
                            
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <Form />
                    </div>
                </div>
            </div>

            <SectionGridLines />
        </section>
    );
};

export default ContactForm;