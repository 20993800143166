// import axios from 'axios';
import React, { useState } from 'react';

const Form = () => {

    const [params, setParams] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        ciudad: "",
        message: "",    
        company: "",
        serviceInterest: ""
    })

    const inputChange = (e) => {
        const { name, value } = e.target;
        setParams({ ...params, [name]: value });
      };

    async function enviarEmail(e){
        e.preventDefault();
        
        const res = await fetch("https://email.keysunshinebusiness.com/api/v.1.0/contact_us", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(params)
        })

        console.log(JSON.stringify(res.json(), null, 2));
        setParams({
            name: "",
            email: "",
            phoneNumber: "",
            ciudad: "",
            message: "",
            company: "",
            serviceInterest: ""
        })
        // console.log(JSON.stringify(params, null, 2));
        // const res = await axios.post("https://email.keysunshinebusiness.com/api/v.1.0/contact_us", params)
        
        // emailjs.sendForm('service_rn4p6wk', 'template_55ykban', e.target, 'HdeNDLIXXOWtaGxD5').then(res=>{
        //     alert("Se ha enviado correctamente");
        //     console.log(res);
        // });
    }
    return (
        <div className="home_contact">
            <form action="contact.php" method="POST" onSubmit={enviarEmail}>
                <input
                    value={params.name}
                    type="text"
                    id="name" 
                    name="name"
                    placeholder="Nombre Completo"
                    className="form-control form-control-lg" 
                    required aria-label=".form-control-lg example"
                    onChange={inputChange}
                />
                <input 
                    value={params.email}
                    name="email" id="email" type="email"
                    className="form-control form-control-lg" 
                    placeholder="Correo Electrónico"
                    required aria-label=".form-control-lg example"
                    onChange={inputChange}
                />
                <input
                    value={params.phoneNumber}
                    className="form-control form-control-lg" 
                    name="phoneNumber" id="phoneNumber" type="number" maxLength={10}
                    placeholder="Teléfono"
                    aria-label=".form-control-lg example"
                    onChange={inputChange}
                />
                <textarea
                    className="form-control pt-4" name="message" id="message" 
                    placeholder="Mensaje" rows="3"
                    onChange={inputChange}
                ></textarea>
                <div className="btn_group">
                    <button type="submit" className="btn olive">Enviar</button>
                </div>
            </form>
        </div>

    );
};

export default Form;