import React from 'react';

import img_sup from '../../assets/images/eternia/Servicios/atraves-sobre.png';
import img_inf from '../../assets/images/eternia/Servicios/nichos-atraves-de-eternia-abajo.png';
import logo_blanco from '../../assets/images/logo-light.svg';
import logo_obscuro from '../../assets/images/logo-dark.svg';
import { Link } from 'react-router-dom';

const AtravesDe = () => {

    return (
        <div className="about style_two recordemos">
            <div className="container-fluid p-0">
                <div className="row align-items-center">
                    <div className="col-lg-1 col-md-1 col-sm-1"></div>
                    <div className="col-lg-5 col-md-5 col-sm-5">
                        <div className="about_image">
                            <img src={img_inf} alt="Imagen representativa de uno de los tipos de nichos de Eternia" className="abt_img_1" />
                            <img src={img_sup} alt="Imagen representativa de uno de los tipos de nichos de Eternia" className="abt_img_2" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-7 col-sm-6 p-0">
                        <div className="about_text_inner">
                            <div className="recordemos-isotipo">
                                <div className="about_text mt-5 txt-recordemos-isotipo">
                                    <div className="icon_box mb-5">
                                        <img src={logo_blanco} alt="Representación del isotipo de Eternia en color negro" className="dark" />
                                        <img src={logo_obscuro} alt="Representación del isotipo de Eternia en color blanco" className="light" />
                                    </div>
                                    <h1 className="text-white mExtraLight fz-32 text-uppercase">BUSCAMOS TRANQUILIDAD <br /> Y BELLEZA</h1>
                                    <h2 className="text-white mBold fz-50">A TRAVÉS DE <br /> NUESTROS NICHOS</h2>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-10 col-md-10 col-sm-10">
                                    <div className="about_text mt-5">
                                        <div className="txt-buscamos-servicios">
                                            <p className='mRegular fz-20 text-white'>
                                                En ETERNIA, entendemos la importancia de elegir un lugar de descanso eterno que refleje el amor y el respeto que sentimos por nuestros seres queridos.
                                            </p>
                                            <p className='mRegular fz-20 text-white'>
                                                Nuestros nichos son cuidadosamente diseñados para ofrecer un espacio de tranquilidad y belleza, donde las memorias perduran y las almas encuentran paz.
                                            </p>
                                            <div className="details_link">
                                                <a href="#seccion-nichos">
                                                    <span className="link_text">CONOCE NUESTROS NICHOS</span>
                                                    <span className="link_icon">
                                                        <span className="line"></span>
                                                        <span className="circle"></span>
                                                        <span className="dot"></span>
                                                    </span>
                                                </a>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                           
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default AtravesDe;