import { GoogleMap, LoadScript } from '@react-google-maps/api';
import React from 'react';

const Map = () => {

    const containerStyle = {
        width: '100%',
        height: '100%'
    };

    const center = {
        lat: 51.508742,
        lng: -0.120850
    };

    return (
        <div className="container-fluid p-0 aos-init aos-animate" data-aos="fade-up" data-aos-duration="3000">
            <div className="row">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.8348886524063!2d-99.67065972452053!3d19.28954514523345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85cd89c872ee31f7%3A0x3afa43be57d840ae!2sAv.%20Miguel%20Hidalgo%20Ote.%201003%2C%20Barrio%20de%20San%20Bernardino%2C%2050080%20Toluca%20de%20Lerdo%2C%20M%C3%A9x.!5e0!3m2!1sen!2smx!4v1695933094000!5m2!1sen!2smx" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    );
};

export default Map;