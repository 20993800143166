import React, { useEffect } from 'react';
import ContactContainer from '../../containers/ContactContainer';
import PageBanner from '../../containers/PageBanner';
import useScrollToDisplay from '../../hooks/useScrollToDisplay';

const Contact = () => {
    useScrollToDisplay('contacto-inicio');
    
    return (
        <>
            <PageBanner pageTitle='Pages' title="CONTACTO" activePage="Contacto"></PageBanner>
            <ContactContainer />
        </>
    );
};

export default Contact;