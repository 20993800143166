import React from 'react';
import { Link } from 'react-router-dom';

import recepcion_gde from '../../assets/images/eternia/recepcion-gde-eternia.png'

const EleganciaSomos = () => {
    return (
        <section className="mission">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <img src={recepcion_gde} alt="Imagen representativa de la recepción del edificio de Eternia" />
                    </div>
                    <div className="col-lg-1"></div>
                </div>

                <div className="mission_bottom_part">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-5 pe-lg-4 mt-5">
                            <div className="text-end">
                                <div className="row">
                                    <div className="col-lg-8 col-md-8 col-sm-8"></div>
                                    <div className="col-lg-4 col-md-4 col-sm-4">
                                        <div className="icon_box mb-5 text-end">
                                            <img src="images/logo-light.svg" alt="Representación del isotipo de Eternia en color negro" className="dark text-end" />
                                            <img src="images/logo-dark.svg" alt="Representación del isotipo de Eternia en color blanco" className="light text-end light-logo-secciones" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="section-header">
                                <h3 className="text-white text-uppercase border-line mMedium fz-50">ELEGANCIA, cuidado Y atención A DETALLE</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 ps-lg-4 p-0">
                            <div className="bg-elegancia">
                                <div className="mission_content">
                                    <div className="row pt-5">
                                        <div className="col-lg-10">
                                            <div className="txt-respeto-seres">
                                                <p className='mRegular fz-20'>
                                                    Somos una empresa líder que entendemos que cada detalle 
                                                    cuenta cuando se trata de honrar y recordar a tus seres queridos
                                                    de una manera excepcional. Nos enorgullece presentarte una 
                                                    experiencia única y personalizada que combina la atención al
                                                    detalle. 
                                                </p>
                                                <p className='mRegular fz-20'>
                                                    Ofrecemos un entorno exclusivo en nuestras instalaciones,
                                                    donde la tranquilidad y la paz se combinan con una decoración 
                                                    sofisticada para crear un ambiente acogedor.
                                                </p>
                                                <div className="details_link">
                                                    <Link to={{
                                                        pathname: '/Servicios-Eternia',
                                                        state: {scrollTo: 'true'}
                                                    }}>
                                                        <span className="link_text">CONOCE NUESTROS NICHOS</span>
                                                        <span className="link_icon">
                                                            <span className="line"></span>
                                                            <span className="circle"></span>
                                                            <span className="dot"></span>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EleganciaSomos;