import React from "react";
import BlogsContainer from "../../containers/BlogContainer/BlogContent";
import PageBanner from "../../containers/BlogContainer/PageBaner";

const Blog = () => {

  return (
    <>
      <PageBanner
        pageTitle="Páginas"
        title="La gratitud es el camino hacia la trascendencia interior"
        activePage="Blog"
      ></PageBanner>
      <main className="wrapper">
        <BlogsContainer  />
      </main>
    </>
  );
};

export default Blog;
