import React, { useEffect } from "react";
import BlogDetailsContainer from "../../containers/BlogContainer/DetailBlogContent";
import PageBanner from "../../containers/BlogContainer/PageBaner";
import { useParams } from "react-router-dom";
import useLoadData from "../../hooks/useLoadData";

const BlogDetails = () => {

  const { article } = useParams();
  const details = useLoadData(`https://blog.eternia.mx/api/v.1.0/article/detail/${article}`);
  
  
  return (
    <>
      <PageBanner
        pageTitle="Páginas"
        title="Biblia: La Biblia es el libro sagrado de los cristianos"
        activePage="Dettales del Blog"
      ></PageBanner>
      <BlogDetailsContainer details={details}/>
    </>
  );
};

export default BlogDetails;
