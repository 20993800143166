import React from 'react';
import { Mousewheel, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import SliderCard02 from '../../components/SliderElements/SliderCard02';
import DignidadHome from '../../components/HomeElements/SliderDignidad';
import img_dignidad from '../../assets/images/eternia/Home/nichos-dignidad-eternia.png'

const Dignidad = () => {
    return (
        <div className="theme_slider_2 p-0">
            <Swiper
                // direction="vertical"
                modules={[Mousewheel, Pagination]}
                className="swiper_theme_slider_2"
                direction={'horizontal'}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false
                }}
                slidesPerView={1}
                loop={false}
                pagination={{
                    clickable: true,
                    dynamicBullets: true,
                }}
                parallax={false}
                mousewheel={false}
                controller={{
                    inverse: true,
                }}
                slideToClickedSlide={true}
                keyboard={{
                    enabled: true,
                }}
            >
                <SwiperSlide>
                    <DignidadHome bgImg={img_dignidad} title='DIGNIDAD Y RESPETO' />
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Dignidad;