import React from 'react';
import { Link } from 'react-router-dom';

const SliderEdificioCard = (props) => {
    return (
        <div className="slider" style={{ backgroundImage: `url(${props.bgImg})` }}>
            <div className="container-fluid p-0">
                <div className="slide_content">
                    <div className="slide_content_wrapper mb-0 h-auto">
                        <div className="slide_content_inner">
                            <div className="row">
                                <div className="col-lg-1"></div>
                                <div className="col-lg-6">
                                    <div className="">
                                        <h4 className=" text-uppercase bRegular fz-100"><a>{props.title}</a></h4>
                                        <p className="mRegular text-uppercase fz-20">
                                            la elegancia y la excelencia se fusionan en un espacio arquitectónico excepcional que sirve como hogar final para aquellos a quienes amamos.
                                        </p>
                                        <div className="details_link">
                                            <p class="p-new-yellow-link">
                                            <Link to="/Avance-Obra">
                                                <span className="link_text mr-1">Avance de obras</span>
                                                <span className="link_icon">
                                                    <span className="line"></span>
                                                    <span className="circle"></span>
                                                    <span className="dot"></span>
                                                </span>
                                            </Link>
                                            </p>
                                            <br />
                                            <Link to="/Contacto">
                                                <span className="link_text mr-1">Conoce más</span>
                                                <span className="link_icon">
                                                    <span className="line"></span>
                                                    <span className="circle"></span>
                                                    <span className="dot"></span>
                                                </span>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="mask-content" >
                            <img src={props.bgImg02} alt="Ilustración del edificio de Eternia" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SliderEdificioCard;