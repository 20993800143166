import React from 'react';
import VideoHome from '../../containers/HomeContainers/VideoHomeEternia';
import EleganciaSomos from '../../containers/SomosContainers/EleganciaContainer';
import SomosEternia from '../../containers/SomosContainers/Somos-Eternia';
import FilosofiaEternia from '../../containers/SomosContainers/FilosofiaEterniaContainer';
import ValoresEternia from '../../containers/SomosContainers/ValoresEternia';
import EdificioEterniSomos from '../../containers/SomosContainers/EdificioEterniaSomos';
import InteresadoNichos from '../../containers/HomeContainers/EstasInteresado';
import useScrollToDisplay from '../../hooks/useScrollToDisplay';


const Somos = () => {
    useScrollToDisplay('somos_title');
    return (
        <main className="wrapper">
            <VideoHome/>
            <EleganciaSomos />
            <SomosEternia/>
            <FilosofiaEternia/>
            <ValoresEternia />
            <EdificioEterniSomos/>
            <InteresadoNichos/>
            
        </main>
    );

};
export default Somos;