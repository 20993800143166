import React from "react";
import { Link } from "react-router-dom";

const PageBanner = (props) => {
  return (
    <>
      <div className="page_header page-blog">
        <div className="page_header_inner">
          <div className="container-fluid">
            <div className="page_header_content d-flex align-items-center justify-content-between">
              <div className="row">
                <div className="col-lg-8 col-md-5 col-sm-12">
                  <h2 className="heading">{props.title}</h2>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-12"></div>
                <div className="col-lg-3 col-md-5 col-sm-12">
                  <div className="pt-2">
                    <ul className="breadcrumb d-flex align-items-center">
                      <li>
                        <Link href="/">Inicio</Link>
                      </li>
                      <li>
                        <Link href="#">{props.pageTitle}</Link>
                      </li>
                      <li className="active">{props.activePage}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageBanner;
