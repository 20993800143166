import React from 'react';

const ValoresCard = (props) => {
    return (
        <div className="icon_box">
            <h6 className='mRegular fz-18'>{props.number}</h6>
            <img src={props.imgDark} alt="Representación del isotipo de Eternia en color negro" className="dark" />
            <img src={props.imgLight} alt="Representación del isotipo de Eternia en color blanco" className="light" />
            <h4 className="text-white mBold fz-28">{props.title}</h4>
            <p className="text-gray-600 mRegular fz-22">{props.text}</p>
            
        </div>

    );
};

export default ValoresCard;