import React from 'react';
import Recordemos from '../../containers/HomeContainers/Recordemos';
import Dignidad from '../../containers/HomeContainers/DignidadRespeto';
import TiposNichosHome from '../../containers/HomeContainers/TiposNichos';
import InteresadoNichos from '../../containers/HomeContainers/EstasInteresado';
import SliderSomos from '../../containers/SomosContainers/SliderSomos';
import useScrollToDisplay from '../../hooks/useScrollToDisplay';

const HomeEternia = () =>{
    useScrollToDisplay('home_title');
    return (
        <div className="wrapper">
            <SliderSomos/>
            <Recordemos/>
            <Dignidad/>
            <TiposNichosHome/>
            <InteresadoNichos/>
        </div>
    );
};

export default HomeEternia;