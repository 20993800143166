import React from "react";


const TerminosCondiciones = () => {
    return (
        <div className='pt-5'>
            <section className="contact_us bg-dark-200">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-lg-12">
                            <div className="section-header aos-init aos-animate" data-aos="fade-up" data-aos-duration="3000">
                                <h1 className="text-white text-uppercase mb-4 mBold fz-38">TÉRMINOS Y CONDICIONES</h1>
                                <p className='mRegular fz-26 text-white'>Términos y condiciones del servicio ETERNIA</p>
                                <ol>
                                    <li>
                                        Introducción.
                                        <p>
                                            Esta página establece los "términos y condiciones" bajo los cuales usted puede
                                            utilizar ETERNIA por favor lea esta página cuidadosamente. Si no acepta los
                                            términos y condiciones establecidos aquí, no utilice este sitio de Internet ni este
                                            servicio.
                                        </p>
                                    </li>
                                    <li>
                                        Aceptación de términos.
                                        <p>
                                            Al utilizar este sitio de Internet, usted acepta obligarse a éstos términos y
                                            condiciones. Los términos y condiciones pueden ser actualizados por ETERNIA en
                                            cualquier momento y sin previo aviso por lo que debe visitar ésta página
                                            periódicamente para consultar la versión más reciente de los términos y
                                            condiciones. Los términos "usted" y "usuario" según se utilizan aquí hacen
                                            referencia a todas las personas y/o entidades que acceden a este sitio de Internet
                                            por cualquier razón.
                                        </p>
                                    </li>
                                    <li>
                                        Acuerdo Obligatorio
                                        <p>
                                            ETERNIA El solo ingreso al portal le otorga al público en general o a quien lo
                                            navegue o use, la condición de usuario e implica la aceptación, plena e
                                            incondicional, de todas y cada una de las condiciones generales y particulares
                                            incluidas en estos términos de uso en la versión publicada por ETERNIA en el
                                            momento mismo en que el usuario acceda al portal. Cualquier modificación a los
                                            presentes términos de uso será realizada cuando ETERNIA lo considere apropiado,
                                            siendo exclusiva responsabilidad de el “usuario” asegurarse de tomar conocimiento
                                            de tales modificaciones.
                                        </p>
                                    </li>
                                    <li>
                                        Descripción del Servicio.
                                        <p>
                                            Queda establecido que usted entiende y acepta que el servicio puede incluir ciertas
                                            comunicaciones de ETERNIA como mensajes administrativos y anuncios de
                                            servicio, éstas comunicaciones son consideradas como parte del servicio de
                                            ETERNIA y usted no puede excluir la recepción de dichas comunicaciones. El uso
                                            de la herramienta online denominada “https://eternia.mx//“ de la compañía ETERNIA
                                            forma parte del servicio otorgado por ETERNIA y su uso se regirá bajo los términos
                                            del presente. La liberación de nuevos productos y propiedades, queda sujeta a
                                            éstos términos. Usted entiende y acuerda que los servicios mencionados
                                            anteriormente son proporcionados “como - es" y ETERNIA de cualquier
                                            comunicación o características de personalización. Si un usuario de ETERNIA
                                            desea dejar de recibir estás comunicaciones, el usuario puede hacer clic en la
                                            solicitud de remoción de envío, que se agrega al final de cada pieza de correo
                                            electrónico enviada.
                                        </p>
                                    </li>
                                    <li>
                                        Otras Reglas Particulares de Uso del Sitio Web:
                                        <p>
                                            Usted representa, garantiza y acepta que usted no usará (o planeará, motivará o
                                            ayudará a otros a usar) el Sitio Web para cualquier otro propósito o que en cualquier
                                            manera esté prohibido por los términos aquí mencionados o los que sean aplicables
                                            por ley. Es su responsabilidad asegurar que usted use el Sitio Web de acuerdo a los
                                            términos aquí especificados.
                                        </p>
                                    </li>
                                    <li>
                                        Reglas de Publicación. Conducta y Seguridad:
                                        <p>
                                            Usted acepta cumplir con las reglas de ETERNIA de Conducta y de Seguridad en
                                            este sitio Web. Los Usuarios que violen dichas reglas tendrán el uso y el acceso del
                                            sitio suspendido o cancelado a discreción exclusiva de ETERNIA.
                                            ETERNIA se reserva el derecho de cambiar estas reglas.
                                            Las reglas de Conducta, Publicación y Seguridad son como se describe a
                                            continuación:
                                        </p>
                                        <ol>
                                            <li>
                                                La información de contacto de ETERNIA está listada en el Sitio Web. ETERNIA y
                                                sus agentes asociados no asumen responsabilidad de que el contenido sea
                                                apropiado o sea bajado fuera de la República Mexicana. El acceso al contenido del
                                                sitio de Internet puede no ser legal para ciertas personas o en ciertos países. Si
                                                usted tiene acceso al contenido del sitio de Internet desde fuera de la República
                                                Mexicana, lo hace a su propio riesgo y es responsable por el cumplimiento de las
                                                leyes dentro de su jurisdicción. El Usuario se compromete a utilizar el Sitio Web y
                                                los Servicios de conformidad con la ley, estas Condiciones Generales, las
                                                Condiciones Particulares aplicables, así como con la moral y buenas costumbres
                                                generalmente aceptadas y el orden público.
                                                ETERNIA excluye cualquier responsabilidad por los daños y perjuicios de toda
                                                naturaleza que puedan deberse a los servicios prestados por terceros a través del
                                                Sitio Web, y en particular, aunque no de modo exclusivo por los daños y perjuicios
                                                que puedan deberse a el incumplimiento de la ley, la moral y las buenas costumbres
                                                generalmente aceptadas o de orden público como consecuencia de la prestación de
                                                servicios por terceros a través del Sitio Web; la infracción de los derechos de
                                                propiedad intelectual e industrial, de los secretos empresariales, de compromisos
                                                contractuales de cualquier clase, de los derechos al honor, a la intimidad personal y
                                                familiar y a la imagen de las personas, de los derechos de propiedad y de toda otra
                                                naturaleza pertenecientes a un tercero como consecuencia de la prestación de
                                                servicios por terceros a través del portal; la realización de actos de competencia
                                                desleal y publicidad ilícita como consecuencia de la prestación de servicios por
                                                terceros a través del portal, la falta de veracidad, exactitud, exhaustividad,
                                                pertinencia y/o actualidad de los contenidos transmitidos, difundidos, almacenados,
                                                recibidos, obtenidos, puestos a disposición o accesibles mediante los servicios
                                                prestados por terceros a través del portal; el incumplimiento, retraso en el
                                                cumplimiento, cumplimiento defectuosos o terminación por cualquier causa de las
                                                obligaciones contraídas por terceros y contratos realizados con terceros en relación
                                                o con motivo de la prestación de servicios a través del portal; los vicios y defectos
                                                de toda clase de los servicios prestados a través del portal. Usted no debe asignar o
                                                transferir sus obligaciones bajo estos términos, estas condiciones constituyen todo
                                                el contrato entre usted y la empresa con respecto al uso del sitio de Internet.
                                                ETERNIA se reserva el derecho a denegar o retirar el acceso al Portal y/o a los
                                                Servicios, en cualquier momento y sin necesidad de previo aviso, a aquellos
                                                usuarios que incumplan estas Condiciones Generales o las Condiciones
                                                Particulares que resulten de
                                            </li>
                                            <li>
                                                Reglas de Conducta:
                                                <ul>
                                                    <li>
                                                        Usted no puede responder a publicaciones a nombre de otros usuarios de
                                                        ninguna manera y para ningún otro propósito que el esperado (ejemplo, para
                                                        aplicar al trabajo o para iniciar una discusión con referencia al trabajo).
                                                        Comunicaciones solicitando el negocio del empleador están prohibidas.
                                                    </li>
                                                    <li>
                                                        Usted no puede enviar correos electrónicos comerciales a los usuarios.
                                                    </li>
                                                    <li>
                                                        Reporte publicaciones o conducta inapropiada a: contacto@eternia.mx
                                                    </li>
                                                    <li>
                                                        Usted no puede enviar correos electrónicos comerciales a los usuarios
                                                    </li>
                                                    <li>
                                                        Reporte publicaciones o conducta inapropiada a: contacto@eternia.mx
                                                    </li>
                                                    <li>
                                                        Usted no puede borrar o revisar ningún material publicado por ninguna otra
                                                        persona o entidad.
                                                    </li>
                                                    <li>
                                                        Si en cualquier momento durante el término ETERNIA se entera que usted lo
                                                        ha engañado en cuanto a su práctica de negocios y/o servicios, y/o ha
                                                        comprado servicios que no representan precisamente su negocio, ETERNIA
                                                        se reserva el derecho de terminar el presente contrato y su uso de privilegios
                                                        inmediatamente sin previa notificación.
                                                    </li>
                                                    <li>
                                                        ETERNIA no tiene obligación de monitorear la conducta de sus usuarios, pero
                                                        se compromete a investigar y responder cuando se reporten violaciones a los
                                                        términos aquí mencionados.
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default TerminosCondiciones;