import React from 'react';
import img_nicho_respeto from '../../assets/images/eternia/Somos/nichos-respeto-seres.png';
import img_respeto_inf from '../../assets/images/eternia/Somos/nichos-ella-derramo-eternia.png';
import { Link } from 'react-router-dom';

const SomosEternia = () => {

    return (
        <div className="about style_two recordemos respeto-somos">
            <div className="container-fluid p-0">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-7 col-sm-6 p-0">
                        <div className="about_text_inner_2">
                            <div className="row">
                                <div className="col-lg-2 col-sm-2 col-md-2"></div>
                                <div className="col-lg-10 col-sm-8 col-md-8">         
                                    <div className="about_text mt-5 txt-respeto-seres">
                                        <h1 className="text-white mBold fz-38 text-uppercase mb-4">Respeto a nuestros seres queridos</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-brindamos-servicios">
                                <div className="row">
                                    <div className="col-lg-2 col-md-2 col-sm-2"></div>
                                    <div className="col-lg-8 col-md-10 col-sm-10">
                                        <div className="txt-respeto-seres">
                                            <div className="about_text mt-5">
                                                <p className='mRegular fz-22 text-white'>
                                                    Nos enfocamos en ser un concepto único y
                                                    exclusivo en el ámbito funerario, brindando
                                                    servicios de alta calidad y respeto, creando 
                                                    espacios finales únicos y significativos para
                                                    honrar la vida, preservando la memoria y el
                                                    legado de quienes han dejado huella en 
                                                    nuestras vidas.
                                                </p>
                                            </div>
                                            <div className="details_link">
                                                <Link to="/Servicios-Eternia">
                                                    <span className="link_text">CONOCE NUESTROS NICHOS</span>
                                                    <span className="link_icon">
                                                        <span className="line"></span>
                                                        <span className="circle"></span>
                                                        <span className="dot"></span>
                                                    </span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-5 col-sm-6">
                        <div className="about_image_2">
                            <img src="images/eternia/Somos/nichos-ella-derramo-eternia.png" alt="Imagen representativa de un tipo de nicho de Eternia" className="abt_img_1_2" />
                            <img src={img_nicho_respeto} alt="Imagen representativa de un tipo de nicho de Eternia" className="abt_img_2_2" />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
};

export default SomosEternia;