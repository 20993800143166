import React from 'react';
import ValoresCard from '../../components/SomosElements/ValoresCard';
import isotipo_blanco from '../../assets/images/eternia/Icons-Eternia/isotipo-ch-bco.svg';
import isotipo_negro from '../../assets/images/eternia/Icons-Eternia/isotipo-negro.svg';
import { Link } from 'react-router-dom';

const ValoresEternia = () => {
    return (
        <main className="wrapper">
            <section className="services inner in-service pb-0">
                <div className="container">
                    <div className="row mb-4">
                        <div className="section-header">
                            <h1 className="text-white fz-38 mBold">VALORES ETERNIA</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-5" >
                            <ValoresCard number="01" imgDark={isotipo_blanco}
                                imgLight={isotipo_negro} title="EMPATÍA Y SENSIBILIDAD"
                                text="Reconocemos la importancia de cada historia y la naturaleza única de cada vida. 
                                        Tratamos a cada familia con empatía y respeto, brindando apoyo en momentos de duelo y ayudando 
                                        a crear un espacio final que honre el legado de sus seres queridos." />
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-5" >
                            <ValoresCard number="02" imgDark={isotipo_blanco}
                                imgLight={isotipo_negro} title="COMPROMISO"
                                text="Operamos con el más alto compromiso en todo lo que hacemos. El compromiso con nuestros procesos es palpable, y nos esforzamos por construir relaciones basadas en la confianza y la honestidad con nuestras familias y socios." />
                        </div>
                       
                        <div className="col-lg-5" >
                            <ValoresCard number="03" imgDark={isotipo_blanco}
                                imgLight={isotipo_negro} title="ESPIRITUALIDAD"
                                text="Celebramos la diversidad de creencias, tradiciones que conforman nuestra comunidad. Respetamos 
                                    las elecciones individuales y ofrecemos opciones que se alineen con las distintas necesidades y deseos 
                                    de las familias que atendemos." />
                        </div>
                        <div className="col-lg-2"></div>
                        <div className="col-lg-5" >
                            <ValoresCard number="04" imgDark={isotipo_blanco}
                                imgLight={isotipo_negro} title="HONOR Y RESPETO POR LA VIDA"
                                text="Valoramos la vida y creemos que cada vida merece ser honrada y recordada de manera digna y respetuosa. 
                                        Nuestro trabajo es un tributo a la vida y una 
                                        forma de mantener viva la memoria de aquellos que amamos." />
                        </div>


                    </div>
                    <div className="row pb-5">
                        <div className="col-lg-8"></div>
                        <div className="col-lg-4">
                            <div className="details_link">
                                <Link to="/Servicios-Eternia">
                                    <span className="link_text">CONOCE NUESTROS NICHOS</span>
                                    <span className="link_icon">
                                        <span className="line"></span>
                                        <span className="circle"></span>
                                        <span className="dot"></span>
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </main>
    );
};

export default ValoresEternia;