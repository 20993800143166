import React from 'react';
import { Link } from 'react-router-dom';

const NichosCard = ({ data }) => {
    const { img, details } = data;

    return (
        <div className="col-lg-4 col-md-4 mb-4">
            <div className='grid-item'>
                <div className="thumb">
                    <img className="item_image" src={img} alt="Imagen ilustrativa de uno de nuestros nichos" />
                    <div className="works-info">
                        <div className="label-text">
                            <h6 className='mExtraLight fz-16'><Link to="/Contacto"> NICHO FUNERARIO</Link></h6>
                            <h5 className='mBold fz-22'><Link to="/Contacto">{details}</Link> </h5>
                            <div className="details_link"><Link to="/Contacto"><span className="link_text">Contáctanos</span> <span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></Link></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NichosCard;