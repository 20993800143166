import React from 'react';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import SliderSomosCard from '../../components/SomosElements/SliderSomosCard';
import edificio from '../../assets/images/eternia/Edificio/edicifio-frontal.png';

const SliderSomos = () => {
    return (
        <div className="theme_slider_4 p-0" id='home_title'>
            <div className="swiper swiper_theme_slider_4">
                <Swiper
                    modules={[Pagination, Autoplay]}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false
                    }}
                    slidesPerView={1}
                    loop={false}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                >
                    <SwiperSlide>
                        <SliderSomosCard bgImg02={edificio} title="CONSERVANDO RECUERDOS ETERNOS" />
                    </SwiperSlide>
                    
                </Swiper>

            </div>
        </div>
    );
};

export default SliderSomos;