import React from 'react';
import VideoServiciosEternia from '../../containers/ServiciosContainers/VideoServicios';
import AtravesDe from '../../containers/ServiciosContainers/ServiciosEternia';
import NichosEternia from '../../containers/ServiciosContainers/Nichos';
import TiposNichosServicios from '../../containers/ServiciosContainers/TiposNichosServicios';
import InteresadoNichos from '../../containers/HomeContainers/EstasInteresado';
import useScrollToDisplay from '../../hooks/useScrollToDisplay';

const Servicios = () =>{
    useScrollToDisplay('servicios_title');
    return(
        <div className=''>
            <VideoServiciosEternia/>
            <AtravesDe/>
            <NichosEternia />
            <TiposNichosServicios id/>
            {/* <PorqueEternia /> */}
            <InteresadoNichos/>
        </div>
    );
};

export default Servicios;