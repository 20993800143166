import React from 'react';
import SlidersEdificio from '../../containers/EdificioContainers/SliderEdificio';
import MissionEdificio from '../../containers/EdificioContainers/MissionEdificio';
import SeccionesEdificio from '../../containers/EdificioContainers/EdificioPartes';
import Map from '../../components/Map';
import InteresadoNichos from '../../containers/HomeContainers/EstasInteresado';
import useScrollToDisplay from '../../hooks/useScrollToDisplay';

const EdificioEternia = () =>{
    useScrollToDisplay('edificios_title');
    return(
        <div className=''>
            <SlidersEdificio/>
            <MissionEdificio/>
            <SeccionesEdificio text='Load More' className='btn gray' />
            <section className="gmap ">
                <div className="gmapbox">
                    <div id="googleMap" className="map">
                        <Map />
                    </div>
                </div>
            </section>
            <InteresadoNichos/>
        </div>
    );
};

export default EdificioEternia;