import React from 'react';
import ContactForm from '../components/ContactForm';
import Map from '../components/Map';

const ContactContainer = () => {

    return (
        <main className="wrapper">
            <ContactForm />
            <section className="gmap ">
                <div className="gmapbox">
                    <div id="googleMap" className="map">
                        <Map />
                    </div>
                </div>
            </section>

        </main>
    );
};

export default ContactContainer;