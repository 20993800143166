import React from "react";
import { Link } from "react-router-dom";

const BlogCard = (props) => {
  
  return (
    <div className="col-lg-6 col-md-6">
      <div className="blog_post mb-0">
        <img src={props.img} alt="img" />
        <div className="blog_content">
          <h2 className="post-count">{props.postCount}</h2>
          <div className="meta">
            <time className="text-olive" dateTime={props.dateTime}>
              {props.date}
            </time>
          </div>
          <h5>
            <Link to={`/Articulo-Blog/${props.url}`} className="text-white">
              {props.title}
            </Link>
          </h5>
          <p>{props.text}</p>
          <div className="details_link">
            <Link to={`/Articulo-Blog/${props.url}`}>
              <span className="link_text">Ver Más</span>{" "}
              
              <span className="link_icon">
                <span className="line"></span> <span className="circle"></span>
                <span className="dot"></span>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogCard;
