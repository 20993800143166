import React from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import PageHeader from '../../components/PageHeder';
import Paragraph from '../../components/Paragraph';
import TiposNichosCard from '../../components/ServiciosElements/TiposNichosCard';
import TeamCard from '../../components/TeamElements/TeamCard';

const TiposNichosServicios = () => {
    const TeamData = [
        {
            id: '1',
            img: 'images/eternia/nuestros-nichos/nuestros-nichos.png',
            name: 'Nicho',
            designation: 'Nicho con capacidad para 8 urnas tamaño estándar. Nuestros nichos están diseñados para resistir el paso del tiempo y mantenerse como testimonios duraderos de amor y de afecto.',
        },
        // {
        //     id: '2',
        //     img: 'images/eternia/tipos-nichos/ejemplo-nicho-2.png',
        //     name: 'Nicho Tipo 2',
        //     designation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
        // },
        // {
        //     id: '3',
        //     img: 'images/eternia/tipos-nichos/ejemplo-nicho-3.png',
        //     name: 'Nicho Tipo 3',
        //     designation: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam',
        // },
       
    ]
    return (
        <section className="team in-team">
            <div className="container">
                <div className="section-header medium">
                    <div className="row">
                        <div className="col-lg-7 col-md-6 col-sm-6">
                            <h1 className='heading mBold fz-50'> NUESTROS <br /> TIPOS DE NICHOS </h1>
                        </div>
                        <div className="col-lg-5 col-md-6 col-sm-6">
                            <div className="section-desc row align-items-center justify-content-center">
                                <div className="mRegular">
                                    <Paragraph text="En ETERNIA, entendemos la importancia de elegir un lugar de descanso eterno que refleje el amor y el respeto que sentimos por nuestros seres queridos." />
                                </div>
                                <div className="mRegular">
                                    <Paragraph text="Nuestros nichos son cuidadosamente diseñados para ofrecer un espacio de tranquilidad y belleza, donde las memorias perduran y las almas encuentran paz." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="team_inner">
                    <div className="row">
                        {
                            TeamData.map(data =>
                                <TiposNichosCard
                                    key={data.id}
                                    data={data}
                                />)
                        }
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TiposNichosServicios;