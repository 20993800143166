import { RouterProvider } from 'react-router-dom';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'react-select2-wrapper/css/select2.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import "swiper/css/effect-coverflow";
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './App.css';
import ThemeSwitcher from './Pages/Shared/ThemeSwitcher';
import { routes } from './Routes/Router';
import './assets/css/style.css';
import HomeEternia from './Pages/HomeEternia/Home';
import Somos from './Pages/Somos/Somos';
import Servicios from './Pages/Servicios/Servicios';
import EdificioEternia from './Pages/Edificio/EdificioEternia';
import Contact from './Pages/Contacto/Contact';
import ErrorPage from './Pages/ErrorPage';
import TerminosCondiciones from './Pages/Legal/Terminos';
import AvisoPrivacidad from './Pages/Legal/Aviso';
import PoliticaCookies from './Pages/Legal/Cookies';



function App() {
  return (
    <div>
      <ThemeSwitcher />
      {/* <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomeEternia />} />
          <Route path="/Quienes-Somos" element={<Somos />} />
          <Route path="/Servicios-Eternia" element={<Servicios />} />
          <Route path="/Edificio-Eternia" element={<EdificioEternia />} />
          <Route path="/Contacto" element={<Contact />} />

          <Route path="*" element={<ErrorPage />} />
          <Route path="/Terminos-Condiciones-Eternia" element={<TerminosCondiciones />} />
          <Route path="/Aviso-Privacidad-Eternia" element={<AvisoPrivacidad />} />
          <Route path="/Politicas-Cookies-Eternia" element={<PoliticaCookies />} />
        </Routes>
      </BrowserRouter> */}
      <RouterProvider router={routes} />
    </div>
  );
}

export default App;
