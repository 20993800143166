import { useEffect } from "react";

const useScrollToDisplay = ( target ) => {

   useEffect(() => { 
      const targetElement = document.getElementById(target);
      if (targetElement) {
         targetElement.scrollIntoView({ behavior: 'smooth', block: 'start'});
      }
   }, [ target])
   
}

export default useScrollToDisplay;