import React from 'react';
import logo_recepcion from '../../assets/images/eternia/Edificio/recepcion-nivel-1-eternia.png';
import logo_blanco from '../../assets/images/logo-light.svg';
import logo_obscuro from '../../assets/images/logo-dark.svg';
import { Link } from 'react-router-dom';

const MissionEdificio = () => {
    return (
        <section className="mission">
            <div className="container-fluid p-0">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <img src={logo_recepcion} alt="Imagen representativa de la recepción del edificio de Eternia" />
                    </div>
                    <div className="col-lg-1"></div>
                </div>

                <div className="mission_bottom_part">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 pe-lg-4 mt-5">
                            <div className="section-header">
                                <div className="text-end mb-4 mt-5">
                                    <div className="row">
                                        <div className="col-sm-8 col-lg-8 col-md-8 "></div>
                                        <div className="col-sm-4 col-lg-4 col-md-4 ">
                                            <div className="icon_box mb-5 text-end">
                                                <img src={logo_blanco} alt="Representación del isotipo de Eternia en color negro" className="dark text-end" />
                                                <img src={logo_obscuro} alt="Representación del isotipo de Eternia en color blanco" className="light text-end light-logo-secciones" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tit-tributo-edificio">
                                    <div className="row">
                                        <div className="col-sm-1 col-lg-4"></div>
                                        <div className="col-sm-11 col-lg-8">
                                            <h4 className="text-white text-uppercase border-line mBold fz-50">ES UN TRIBUTO A LA VIDA</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 ps-lg-4 p-0">
                            <div className="bg-elegancia">
                                <div className="mission_content">
                                    <div className="row pt-5">
                                        <div className="col-lg-10">
                                            <div className="txt-respeto-seres mt-5">
                                                <p className='mRegular fz-22'>
                                                    Nuestras innovadoras instalaciones ubicadas en la ciudad de Toluca,
                                                    México son mucho más que un edificio, es un tributo a la vida y
                                                    un lugar de reposo eterno que personifica la dignidad y el prestigio.
                                                </p>
                                                <p className='mRegular fz-22 mb-5'>
                                                    Nuestro nichos se integran perfectamente en su diseño,
                                                    proporcionando un lugar de descanso eterno que complementa la
                                                    belleza de las instalaciones.
                                                </p>
                                                <div className="details_link">
                                                    <Link to="/Contacto">
                                                        <span className="link_text">CONTÁCTANOS</span>
                                                        <span className="link_icon">
                                                            <span className="line"></span>
                                                            <span className="circle"></span>
                                                            <span className="dot"></span>
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MissionEdificio;