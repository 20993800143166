import React from 'react';
import SlidersEdificio from '../../containers/EdificioContainers/SliderEdificio';
import MissionEdificio from '../../containers/EdificioContainers/MissionEdificio';
import SeccionesObra from '../../containers/ObrasContainer/ObrasPartes';
import Map from '../../components/Map';
import InteresadoNichos from '../../containers/HomeContainers/EstasInteresado';
import useScrollToDisplay from '../../hooks/useScrollToDisplay';


const ObraProceso = () =>{
    useScrollToDisplay('edificios_title');
    return(
        <div className=''>
            <SeccionesObra text='Load More' className='btn gray' />
        </div>
    );
};

export default ObraProceso;