import React from 'react';
import logo_blanco from '../../assets/images/logo-light.svg';
import { Link } from 'react-router-dom';

const FilosofiaEternia = () => {
    return (
        <div className='bg-filosofia'>
            <div className="container">
                <div className="row">
                    <div className="txt-filosofia">
                        <div className="text-center mb-5">
                            <img src={logo_blanco} alt="Logo de color blanco de Eternia" className='mb-5 logo-filosofia' />
                            <h3 className='"text-white text-uppercase border-line bRegular fz-80'>NUESTRA FILOSOFÍA</h3>
                            <div className="row">
                                <div className="col-lg-4"></div>
                                <div className="col-lg-4">
                                    <div className="borde-filosofia"></div>
                                </div>
                                <div className="col-lg-4"></div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-1 col-sm-1 col-md-1"></div>
                            <div className="col-lg-5 col-sm-5 col-md-5">
                                <p className="mRegular">
                                    En ETERNIA, comprendemos la importancia de honrar y
                                    recordar a nuestros seres queridos de una manera significativa
                                    y respetuosa. Nuestro compromiso es brindar un espacio de
                                    descanso eterno que refleje el legado y los valores de aquellos
                                    a quienes amaste.
                                </p>
                            </div>
                            <div className="col-lg-5 col-sm-5 col-md-5">
                                <p className="mRegular">
                                    Queremos ser una fuente de esperanza y acompañamiento
                                    durante el proceso de duelo, ofreciendo a las familias un
                                    espacio para honrar a sus  seres queridos de una manera
                                    auténtica y memorable.
                                </p>
                            </div>
                            <div className="col-lg-1 col-sm-1 col-md-1"></div>
                        </div>
                        <div className="row mt-5">
                            <div className="col-lg-7 col-sm-7 col-md-7"></div>
                            <div className="col-lg-4 col-sm-4 col-md-4">
                                <div className="details_link">
                                    <Link to="/Servicios-Eternia">
                                        <span className="link_text">CONOCE NUESTROS NICHOS</span>
                                        <span className="link_icon">
                                            <span className="line"></span>
                                            <span className="circle"></span>
                                            <span className="dot"></span>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-1 col-sm-1 col-md-1"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilosofiaEternia;