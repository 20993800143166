import React from 'react';
import NichosCard from '../../components/ServiciosElements/NichosCard';
import SectionGridLines from '../../components/SectionGridLines';



const NichosEternia = () => {
    const ProjectData01 = [
        {
            id: '1',
            img: 'images/eternia/nuestros-nichos/nicho-1.png',
            details: 'Nicho para 8 urnas',
            tag: 'interiors'
        },
        {
            id: '2',
            img: 'images/eternia/nuestros-nichos/nicho-estilo-2.png',
            details: 'Nicho para 8 urnas',
            tag: 'residences'
        },
        {
            id: '3',
            img: 'images/eternia/nuestros-nichos/nicho-3.png',
            details: 'Nicho para 8 urnas',
            tag: 'residences'
        },
        {
            id: '4',
            img: 'images/eternia/nuestros-nichos/nicho-4.png',
            details: 'Nicho para 8 urnas',
            tag: 'landscape'
        },
        {
            id: '5',
            img: 'images/eternia/nuestros-nichos/nicho-5.png',
            details: 'Nicho para 8 urnas',
            tag: 'residences'
        },
        {
            id: '6',
            img: 'images/eternia/nuestros-nichos/nicho-estilo-6.png',
            details: 'Nicho para 8 urnas',
            tag: 'residences'
        },
        {
            id: '7',
            img: 'images/eternia/nuestros-nichos/nicho-7.png',
            details: 'Nicho para 8 urnas',
            tag: 'residences'
        },
        {
            id: '8',
            img: 'images/eternia/nuestros-nichos/nicho-8.png',
            details: 'Nicho para 8 urnas',
            tag: 'residences'
        },
        {
            id: '9',
            img: 'images/eternia/nuestros-nichos/nicho-estilo-9.png',
            details: 'Nicho para 8 urnas',
            tag: 'residences'
        },
    ];


    return (
        <section className="projects packery" id='seccion-nichos'>
            <SectionGridLines />
            <div className="container">
                <div className="section-header text-center fz-90">
                    <div className="row">
                        <div className="col-lg-4 col-sm-4 col-md-4"></div>
                        <div className="col-lg-4 col-sm-4 col-md-4">
                            <div className="icon_box mb-5 text-center">
                                <img src="images/logo-light.svg" alt="Representación del isotipo de Eternia en color negro" className="dark text-center" />
                                <img src="images/logo-dark.svg" alt="Representación del isotipo de Eternia en color blanco" className="light text-center ms-5" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-4 col-md-4"></div>
                    </div>
                    <h1 className='text-white bRegular fz-100'> NUESTROS NICHOS </h1>
                    
                </div>
                <div className="row">
                    {
                        ProjectData01.map( data=> <NichosCard
                            key={data.id}
                            data={data}
                        />)
                    }
                </div>

              

            </div>
        </section>
    );
};

export default NichosEternia;